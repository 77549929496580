(function(){
    'use strict';

    angular.module('aerosApp')
        .component('aflModalCarouselRow', {
            controller: aflModalCarouselRowController,
            templateUrl: '/static/javascript/directives/afl-modal-carousel/afl-modal-carousel-row/afl-modal-carousel-row.html',
            bindings: {
                group: '=',
                generic: '=',
                fieldDescription: '=',
                model: '=',
                mode: '=',
                name: '=',
                fieldName: '=',
                innerClass: '@',
                required: '=',
                units: '=',
                visible: '=',
                fieldChangeEventHandler: '&'
            }
        });

    function aflModalCarouselRowController() {
        var $ctrl = this;

        $ctrl.setVisibility = function setVisibility() {

            if(getHiddenByMode($ctrl.mode, $ctrl.fieldDescription)) {
                $ctrl.isVisible = false;
            } else {
                if (typeof $ctrl.visible !== "undefined") {
                    $ctrl.isVisible = !!$ctrl.visible;
                } else {
                    $ctrl.isVisible = $ctrl.fieldDescription.type !== 'hidden'
                }
            }

        };

        (function init(){
            $ctrl.setVisibility();
        })();

        $ctrl.rowChangeEventHandler = function(properties) {
            $ctrl.fieldChangeEventHandler({properties: properties});
            //$ctrl.fieldChangeEventHandler({model: model});
        };

        $ctrl.getNameWithUpdatedPatterns = function getNameWithUpdatedPatterns(field) {
            var updatedStr = field.name;

            if (this.model && this.model.lossDistanceUnit && field && field.fieldDescription
                && field.fieldDescription.showUnit === 'lossDistUnit') {
                updatedStr = updatedStr.replace('%%LOST_DIST_UNIT%%', this.model.lossDistanceUnit);
            }

            return updatedStr;
        };

        function getHiddenByMode(mode, fieldDescription) {
            var modes = ["VIEW", "ADD", "EDIT", "ADDPROJECT", "EDITPROJECT"];
            if(fieldDescription && fieldDescription.hiddenModes) {
                return fieldDescription.hiddenModes.indexOf(modes[mode]) >= 0;
            }
        }

    }
})();
